const axios = require('axios')

export const getToken = async (identity, room) => {

    const res = await axios.post('https://staging.api.ubelong.io/twilio', {
        identity: identity,
        room: room
    })
    return res.data.token
}
