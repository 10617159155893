import React, { useState, useRef, useEffect } from 'react'
import './Participant.scss';
import handImg from './assets/images/hand.svg'
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import { useDispatch, useSelector } from 'react-redux'
import { SET_LOCAL_ZOOM } from './redux/states'

function Participant({
    roomName,
    participant,
    cameraOrScreen,
    remote,
    widthAndHeight,
    borderRadius,
    isMargin,
    name,
    isSpeaking,
    isHand,
    sidLocalParticipant,
    isHidden,
    isMuted,
    changeMute,
    handleOnFullScreen,
    isCompleteMode,
    right
}) {

    const [videoTracks, setVideoTracks] = useState([]);
    const [audioTracks, setAudioTracks] = useState([]);

    const videoRef = useRef();
    const audioRef = useRef();
    const videoRef2 = useRef();

    const dispatch = useDispatch()

    const localZoom = useSelector(state => {
        console.log(state.localSidZoom)
        return state.localSidZoom
    })

    const trackpubsToTracks = (trackMap) =>
        Array.from(trackMap.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null);

    useEffect(() => {
        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.audioTracks));

        const trackSubscribed = (track) => {
            if (track.kind === "video") {
                setVideoTracks((videoTracks) => [...videoTracks, track]);
            } else if (track.kind === "audio") {
                setAudioTracks((audioTracks) => [...audioTracks, track]);
            }
        };

        const trackUnsubscribed = (track) => {
            if (track.kind === "video") {
                setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
            } else if (track.kind === "audio") {
                setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
            }
        };

        participant.on("trackSubscribed", trackSubscribed);
        participant.on("trackUnsubscribed", trackUnsubscribed);

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
        };
    }, [participant]);

    useEffect(() => {
        if (videoTracks?.length) {
            let videoTrack
            videoTracks.forEach((video) => {

                videoTrack = video;
                if (videoTrack) {
                    if (video.name !== 'screen' && cameraOrScreen !== 'screen') {
                        videoTrack.attach(videoRef.current)

                    }
                    if (video.name !== 'camera' && cameraOrScreen !== 'camera') {

                        videoTrack.attach(videoRef2.current)
                    }


                    return () => {
                        videoTrack.detach();
                    };
                }
            })
        }
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);

    return (
        // <>
        <div hidden={isHidden}>
            <div className={!isMargin ? 'participant' : 'participant-margin'} style={{
                width: !isCompleteMode ? widthAndHeight.width : '75%'
            }}>
                <div className={right ? 'participants-right' : ''} style={{ position: 'relative', width: '100%' }}>

                    {
                        participant.videoTracks.size > 0 &&
                        videoTracks?.length > 1 ?
                            <video
                                onDoubleClick={() => {
                                    right ?
                                    dispatch({
                                        type: SET_LOCAL_ZOOM,
                                        payload: {
                                            isCompleteMode: true,
                                            fullSid: participant.sid,
                                            cameraOrScreen: cameraOrScreen
                                        }
                                    })
                                    :
                                    dispatch({
                                        type: SET_LOCAL_ZOOM,
                                        payload: {
                                            isCompleteMode: !localZoom.data.isCompleteMode,
                                            fullSid: participant.sid,
                                            cameraOrScreen: cameraOrScreen
                                        }
                                    })
                                }}
                                className='participant-video'
                                ref={cameraOrScreen === 'camera' ? videoRef : videoRef2}
                                autoPlay={true}
                                style={{ borderRadius: borderRadius, transform: cameraOrScreen === 'camera' ? 'scale(-1,1)' : '' }} />


                            :
                            <video
                                onDoubleClick={() => {
                                    right ?
                                    dispatch({
                                        type: SET_LOCAL_ZOOM,
                                        payload: {
                                            isCompleteMode: true,
                                            fullSid: participant.sid,
                                            cameraOrScreen: cameraOrScreen
                                        }
                                    })
                                    :
                                    dispatch({
                                        type: SET_LOCAL_ZOOM,
                                        payload: {
                                            isCompleteMode: !localZoom.data.isCompleteMode,
                                            fullSid: participant.sid,
                                            cameraOrScreen: cameraOrScreen
                                        }
                                    })
                                }}
                                className='participant-video'
                                ref={videoRef}
                                autoPlay={true}
                                style={{ borderRadius: borderRadius, transform: 'scale(-1,1)' }}
                            />
                    }
                    {
                        isHand &&
                        <div className={ !right ? 'participant-hand' : 'participant-right-hand'}>
                            <img alt='hand' src={handImg} />
                        </div>
                    }
                </div>

                <audio hidden={isHidden} ref={audioRef} autoPlay={true} />
            </div>
            <div className={!right ? 'participant-overlay': 'participant-overlay-mini'}>
                {/* <div className={isSpeaking ? 'participant-overlay-speaker-on' : 'participant-overlay-speaker'}></div>
                    <div className={isSpeaking ? 'participant-overlay-speaker-on' : 'participant-overlay-speaker'}></div>
                    <div className={isSpeaking ? 'participant-overlay-speaker-on' : 'participant-overlay-speaker'}></div> */}
                {
                    sidLocalParticipant !== participant.sid ?
                        <p className='participant-overlay-name'>{name?.length ? name : ''}</p> :
                        <p className='participant-overlay-name'>{name?.length ? `${name}  ( Usted )` : ''}</p>
                }
                {
                    sidLocalParticipant !== participant.sid && !isNaN(changeMute) &&
                    (!isMuted ?
                        <MicOffIcon className={!right ? 'participant-overlay-micoff' : 'participant-overlay-mini-micoff'} /> :
                        <MicIcon className={!right ? 'participant-overlay-micon' : 'participant-overlay-mini-micon'} />)
                }
                {
                    sidLocalParticipant === participant.sid &&
                    (!isMuted ?
                        <MicOffIcon className={!right ? 'participant-overlay-micoff' : 'participant-overlay-mini-micoff'} /> :
                        <MicIcon className={!right ? 'participant-overlay-micon' :  'participant-overlay-mini-micon'} />)
                }
            </div>
        </div>
        // </>
    )
}

export default Participant;
