import React, { useState, useEffect, useCallback } from 'react'
import Form from './Form'
import UbelongVideoCall from './UbelongVideoCall'
import { useDispatch, useSelector } from 'react-redux'
import './App.scss';
import { connect, LocalDataTrack, } from 'twilio-video'
import { getToken } from './helpers/methods/getToken'
import { SET_LOCAL_PARTICIPANT } from './redux/states'


function App() {

  const [name, setName] = useState('')
  const [roomName, setRoomName] = useState('')
  const [moderator, setModerator] = useState(false)
  const [room, setRoom] = useState()
  const localParticipant = useSelector(state => state.localParticipantReducer)
  const dispatch = useDispatch()
  const dataTrack = new LocalDataTrack({
    maxRetransmits: 10000000000000,
  })

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      handleOnSubmitVideoCall()
    }
  }, [])

  const handleOnChangeName = ({ target: { value } }) => {
    setName(value)
    dispatch({
      type: SET_LOCAL_PARTICIPANT,
      payload: {
        ...localParticipant.data,
        name: value
      }
    })
  }
  const handleOnChangeRoom = ({ target: { value } }) => {
    setRoomName(value)
    dispatch({
      type: SET_LOCAL_PARTICIPANT,
      payload: {
        ...localParticipant.data,
        roomName: value
      }
    })
  }
  const handleOnChangeModerator = (value) => {
    setModerator(value)
    dispatch({
      type: SET_LOCAL_PARTICIPANT,
      payload: {
        ...localParticipant.data,
        moderator: value
      }
    })
  }

  const handleOnSubmitVideoCall = async () => {
    try {
      let token
      let tokenLocalStorage = localStorage.getItem('token')
      let roomNameLocalStorage = localStorage.getItem('roomName')
      if (!tokenLocalStorage) {
        token = await getToken(name, roomName)
        localStorage.setItem('token', token)
        localStorage.setItem('roomName', roomName)
      }else {
        token = tokenLocalStorage
      }

      connect(token, {
        name: roomName /*? roomName : roomNameLocalStorage*/,
        audio: { name: 'microphone' },
        video: {
           name: 'camera',
          height: 720,
          width: {
            ideal: 720 * (16/9),
            min: 500 * (16/9),
            max: 900 * (16/9)
          },
          aspectRatio: 16/9
         },
        dominantSpeaker: true,
        preferredVideoCodecs: ['VP8', 'VP9', 'H264'],
        

        // networkQuality: {
        //   local: 3,
        // },
      }).then(function (room) {
        console.log(dataTrack.maxRetransmits)
        room.localParticipant.publishTrack(dataTrack)
        setRoom(room)
        room.on('dominantSpeakerChanged', participant => {
          console.log('The new dominant speaker in the Room is:', participant);
        })
      })
    } catch (error) {

    }
  }

  const handleLogout = useCallback(() => {

    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          if (trackPub.track.kind !== 'data') trackPub.track.stop()
        })
        prevRoom.disconnect()
      }
      return null
    })
  }, [])


  return (
    <div className="app">
      { 
      !room ?
        !localStorage.getItem('token')?.length &&
        <Form
          changeName={handleOnChangeName}
          changeRoomName={handleOnChangeRoom}
          changeModerator={handleOnChangeModerator}
          onSubmit={handleOnSubmitVideoCall}
          moderator={moderator}
        />
       :
      
      
        <UbelongVideoCall
          roomName={roomName}
          room={room}
          key={room.localParticipant.sid}
          participant={room.localParticipant}
          moderator={moderator}
          handleLogout={handleLogout}
        />
      }
    </div>
  )
}

export default App
