import React, { useState, useEffect, useCallback } from 'react'
import './UbelongVideoCall.scss';
import Participant from './Participant'
import CamerasOff from './CamerasOff'
import { LocalVideoTrack, LocalDataTrack } from 'twilio-video'
import { getCameraBorderRadius } from './helpers/methods/getCameraBorderRadius'
import cameraImg from './assets/images/camera.svg'
import cameraOffImg from './assets/images/cameraOff.svg'
import micOnImg from './assets/images/micOn.svg'
import micOffImg from './assets/images/micOff.svg'
import shareScreenImg from './assets/images/shareScreen.svg'
import recordImg from './assets/images/record.svg'
import handImg from './assets/images/hand.svg'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { useDispatch, useSelector } from 'react-redux'
import { SET_LOCAL_ZOOM } from './redux/states'





function UbelongVideoCall({ roomName, room, moderator, participant, handleLogout, handleOnMessage }) {

    const [participants, setParticipants] = useState([])
    const [participantsHands, setParticipantsHands] = useState([])
    const [currentSpeaker, setCurrentSpeaker] = useState('')
    const [isMuted, setIsMuted] = useState(true)
    const [isScreen, setIsScreen] = useState(true)
    const [screenSharedRemote, setScreenSharedRemote] = useState(0)
    const [isShared, setIsShared] = useState(false)
    const [wantToSpeak, setWantToSpeak] = useState(false)  //for change hand button color
    const [screenTrack, setScreenTrack] = useState()
    const [sidLocalParticipant, setSidLocalParticipant] = useState()
    const [camerasOff, setCamerasOff] = useState([])
    const [isLocalHidden, setIsLocalHidden] = useState(false)
    const [changeMute, setIsChangeMute] = useState(0)
    const [isCompleteMode, setIsCompleteMode] = useState(false)
    const [fullSid, setFullSid] = useState('')

    // const dataTrack = new LocalDataTrack()


    const dispatch = useDispatch()

    const localZoom = useSelector(state => {
        console.log(state.localSidZoom)
        return state.localSidZoom
    })


    useEffect(() => {
        setSidLocalParticipant(participant.sid)
        if (!screenTrack) {
            setParticipants((prevParticipants) => {

                /* isDeleteSharing is for know when the videotrack 
                deleted is a screen share  and don't push 
                the same participant again */

                const isDeleteSharing = prevParticipants.map(prev => prev.identity === participant.identity).includes(true)
                if (isDeleteSharing) return [...prevParticipants]
                else return [...prevParticipants, participant]
            });
        }
        else {
        }

    }, [screenTrack])

    useEffect(() => {
        debugger
        const participantConnected = (participant) => {
            setParticipants((prevParticipants) => [...prevParticipants, participant]);
        };

        const participantDisconnected = (participant, sid = localZoom.data.fullSid) => {
            dispatch({
                type: SET_LOCAL_ZOOM,
                payload: {
                    isCompleteMode: false,
                    fullSid: '',
                }
            })
            setParticipants((prevParticipants) => {
                console.log('Desconectado ')
                return prevParticipants.filter((p) => p !== participant)
            }
            );
        };

        const dominantSpeaker = (participant) => {
            if (participant) setCurrentSpeaker(participant?.sid)
        };

        room.on("participantConnected", participantConnected)
        room.on("participantDisconnected", participantDisconnected)
        room.on("dominantSpeakerChanged", dominantSpeaker)
        room.participants.forEach(participantConnected)
        return () => {
            room.off("participantConnected", participantConnected)
            room.off("participantDisconnected", participantDisconnected)
            room.off("dominantSpeakerChanged", dominantSpeaker)
        };
    }, [room, handleLogout]);


    useEffect(() => {
        const participantMessage = (data, remoteTrack, participant) => {
            debugger
            screenSharedRemote === 0 ?
                setScreenSharedRemote(prev => prev + 1) :
                setScreenSharedRemote(prev => prev - 1)

                (async()=>{
                    await room.localParticipant.dataTracks.delete(
                        room.localParticipant.dataTracks.keys().next().value)
                })()
            switch (data) {
                case 'hand':
                    if (participantsHands.some(p => p.sid === participant.sid)) {
                        setParticipantsHands((prevParticipantsHands) =>
                            prevParticipantsHands.filter((p) => p.sid !== participant.sid)
                        )
                    } else {
                        setParticipantsHands(prevParticipantsHands => {
                            return [...prevParticipantsHands, participant]
                        })
                    }

                    break;
                case 'screen':
                    debugger
                    screenSharedRemote === 0 ?
                        setScreenSharedRemote(prev => prev + 1) :
                        setScreenSharedRemote(prev => prev - 1)

                    // participant.dataTracks.delete(remoteTrack.sid)
                    break;
                case 'screenoof':
                    debugger
                    screenSharedRemote === 0 ?
                        setScreenSharedRemote(prev => prev + 1) :
                        setScreenSharedRemote(prev => prev - 1)

                    // participant.dataTracks.delete(remoteTrack.sid)
                    break;
                default:
                    break;
            }
        }
        const trackUnsubscribed = (data, remoteTrack, participant) => {
            debugger
            console.log(data)
        }

        room.on('trackMessage', participantMessage)
        // room.on('trackSubscribed', participantMessage)
        room.on('trackUnsubscribed', trackUnsubscribed)
        return () => {
            room.off("trackMessage", participantMessage)
            // room.off('trackUnsubscribed', trackUnsubscribed)
        };
    }, [room, participantsHands, participants]);


    const handleOnIncrees = useCallback(
        (cameras) => {
            let width = 80 / Math.ceil(Math.sqrt(cameras))
            let height = 80 / Math.ceil(Math.sqrt(cameras))
            width = width.toFixed(1)
            height = height.toFixed(1)
            return { width: `${width.toString()}vw`, height: `${height.toString()}vh` }
        },
        [],
    )

    useEffect(() => {
        if (room) {
            const tidyUp = (event) => {
                if (event.persisted) {
                    return;
                }
                if (room) {
                    handleLogout();
                }
            };
            window.addEventListener("pagehide", tidyUp);
            window.addEventListener("beforeunload", tidyUp);
            return () => {
                window.removeEventListener("pagehide", tidyUp);
                window.removeEventListener("beforeunload", tidyUp);
            };
        }
    }, [room, handleLogout]);

    // const handleOnFullScreen = (fullScreenSid) => {
    //     setFullSid(fullScreenSid)
    //     setIsCompleteMode(!localZoom.data.isCompleteMode)
    // }

    const handleOnCountVideoTrack = () => {
        let count = 0
        participants.forEach(p => {
            console.log(room.localParticipant.videoTracks.size)

            count += p.videoTracks.size
        })
        return count
    }
    const remoteParticipants = participants.map((participant, i) => {
        console.log(participants)
        debugger
        let videosLenght = handleOnCountVideoTrack() // Count cameras including screen sharing 
        const sizes = handleOnIncrees(videosLenght - camerasOff.length - isLocalHidden)
        const borderRadius = getCameraBorderRadius(videosLenght - camerasOff.length - isLocalHidden, i)
        // participant.videoTracks.size
        if (participant.videoTracks.size <= 1) {
            console.log(participant.identity + '   ' + borderRadius + '    ' + sizes.width + '  ' + sizes.height)
            return <Participant
                remote={true}
                // key={participant.sid}
                participant={participant}
                name={participant.identity}
                widthAndHeight={sizes}
                numParticipants={videosLenght}
                borderRadius={borderRadius}
                isMargin={videosLenght <= 3}
                isSpeaking={currentSpeaker === participant.sid}
                isHand={participantsHands.some(p => p.sid === participant.sid)}
                // isHand={!participantsHands.some(p => p.sid === participant.sid) ?
                //     participant.dataTracks.size > 0 :
                //     participantsHands.some(p => p.sid === participant.sid)
                // }
                isMuted={participant.audioTracks.get(participant.audioTracks.keys().next().value).isTrackEnabled}
                sidLocalParticipant={sidLocalParticipant}
                isHidden={!participant.videoTracks.get(participant.videoTracks.keys().next().value).isTrackEnabled}
                changeMute={changeMute}
                // handleOnFullScreen={handleOnFullScreen}
                isCompleteMode={localZoom.data.isCompleteMode}
            />


        } else {

            return <>
                <Participant
                    remote={true}
                    // key={participant.sid}
                    participant={participant}
                    cameraOrScreen={'camera'}
                    name={participant.identity}
                    widthAndHeight={sizes}
                    numParticipants={videosLenght}
                    borderRadius={borderRadius}
                    isMargin={videosLenght <= 3}
                    isSpeaking={currentSpeaker === participant.sid}
                    isHand={participantsHands.some(p => p.sid === participant.sid)}
                    // isHand={!participantsHands.some(p => p.sid === participant.sid) ?
                    //     participant.dataTracks.size > 0 :
                    //     participantsHands.some(p => p.sid === participant.sid)
                    // }
                    isMuted={participant.audioTracks.get(participant.audioTracks.keys().next().value).isTrackEnabled}
                    sidLocalParticipant={sidLocalParticipant}
                    isHidden={!participant.videoTracks.get(participant.videoTracks.keys().next().value).isTrackEnabled}
                    changeMute={changeMute}
                    // handleOnFullScreen={handleOnFullScreen}
                    isCompleteMode={localZoom.data.isCompleteMode}
                />
                <Participant
                    remote={true}
                    // key={participant.sid}
                    participant={participant}
                    cameraOrScreen={'screen'}
                    name={participant.identity}
                    widthAndHeight={sizes}
                    numParticipants={participants.length}
                    borderRadius={borderRadius}
                    isMargin={participants.length <= 3}
                    isSpeaking={currentSpeaker === participant.sid}
                    isHand={participantsHands.some(p => p.sid === participant.sid)}
                    // isHand={!participantsHands.some(p => p.sid === participant.sid) ?
                    //     participant.dataTracks.size > 0 :
                    //     participantsHands.some(p => p.sid === participant.sid)
                    // }
                    isMuted={participant.audioTracks.get(participant.audioTracks.keys().next().value).isTrackEnabled}
                    sidLocalParticipant={sidLocalParticipant}
                    isHidden={!participant.videoTracks.get(participant.videoTracks.keys().next().value).isTrackEnabled}
                    changeMute={changeMute}
                    // handleOnFullScreen={handleOnFullScreen}
                    isCompleteMode={localZoom.data.isCompleteMode}
                />
            </>
        }
    }
    );

    const participantsRight = participants.map((participant, i) => {
        const borderRadius = getCameraBorderRadius(2, i)
        let videosLenght = handleOnCountVideoTrack() // Count cameras including screen sharing 
        if (participant.sid !== localZoom.data.fullSid) {
            debugger
            if (participant.videoTracks.size <= 1) {
                debugger
                return <Participant
                    remote={true}
                    // key={participant.sid}
                    participant={participant}
                    name={participant.identity}
                    widthAndHeight={''}
                    numParticipants={participants.length}
                    borderRadius={borderRadius}
                    isMargin={participants.length <= 3}
                    isSpeaking={currentSpeaker === participant.sid}
                    isHand={participantsHands.some(p => p.sid === participant.sid)}
                    isMuted={participant.audioTracks.get(participant.audioTracks.keys().next().value).isTrackEnabled}
                    sidLocalParticipant={sidLocalParticipant}
                    isHidden={!participant.videoTracks.get(participant.videoTracks.keys().next().value).isTrackEnabled}
                    changeMute={changeMute}
                    // handleOnFullScreen={handleOnFullScreen}
                    right={true}
                    isCompleteMode={localZoom.data.isCompleteMode}
                />
            }
            else {
                debugger
                return <>
                    <Participant
                        remote={true}
                        // key={participant.sid}
                        participant={participant}
                        cameraOrScreen={'camera'}
                        name={participant.identity}
                        widthAndHeight={''}
                        numParticipants={videosLenght}
                        borderRadius={borderRadius}
                        isMargin={videosLenght <= 3}
                        isSpeaking={currentSpeaker === participant.sid}
                        isHand={participantsHands.some(p => p.sid === participant.sid)}
                        // isHand={!participantsHands.some(p => p.sid === participant.sid) ?
                        //     participant.dataTracks.size > 0 :
                        //     participantsHands.some(p => p.sid === participant.sid)
                        // }
                        isMuted={participant.audioTracks.get(participant.audioTracks.keys().next().value).isTrackEnabled}
                        sidLocalParticipant={sidLocalParticipant}
                        isHidden={!participant.videoTracks.get(participant.videoTracks.keys().next().value).isTrackEnabled}
                        changeMute={changeMute}
                        // handleOnFullScreen={handleOnFullScreen}
                        right={true}
                        isCompleteMode={localZoom.data.isCompleteMode}
                    />
                    <Participant
                        remote={true}
                        // key={participant.sid}
                        participant={participant}
                        cameraOrScreen={'screen'}
                        name={participant.identity}
                        widthAndHeight={''}
                        numParticipants={participants.length}
                        borderRadius={borderRadius}
                        isMargin={participants.length <= 3}
                        isSpeaking={currentSpeaker === participant.sid}
                        isHand={participantsHands.some(p => p.sid === participant.sid)}
                        // isHand={!participantsHands.some(p => p.sid === participant.sid) ?
                        //     participant.dataTracks.size > 0 :
                        //     participantsHands.some(p => p.sid === participant.sid)
                        // }
                        isMuted={participant.audioTracks.get(participant.audioTracks.keys().next().value).isTrackEnabled}
                        sidLocalParticipant={sidLocalParticipant}
                        isHidden={!participant.videoTracks.get(participant.videoTracks.keys().next().value).isTrackEnabled}
                        changeMute={changeMute}
                        // handleOnFullScreen={handleOnFullScreen}
                        right={true}
                        isCompleteMode={localZoom.data.isCompleteMode}
                    />
                </>
            }
        }
    }
    );

    const fullScreen = participants.map((participant, i) => {
        debugger
        const sizes = handleOnIncrees(1)
        const borderRadius = getCameraBorderRadius(2, i)
        let count = 0
        if (participant.sid === localZoom.data.fullSid) {
            count++
            return <Participant
                remote={true}
                // key={participant.sid}
                participant={participant}
                name={participant.identity}
                widthAndHeight={sizes}
                numParticipants={participants.length}
                cameraOrScreen={localZoom.data.cameraOrScreen}
                borderRadius={borderRadius}
                isMargin={participants.length <= 3}
                isSpeaking={currentSpeaker === participant.sid}
                isHand={participantsHands.some(p => p.sid === participant.sid)}
                isMuted={participant.audioTracks.get(participant.audioTracks.keys().next().value).isTrackEnabled}
                sidLocalParticipant={sidLocalParticipant}
                isHidden={!participant.videoTracks.get(participant.videoTracks.keys().next().value).isTrackEnabled}
                changeMute={changeMute}
            // handleOnFullScreen={handleOnFullScreen}
            />
        }
        // if(i === participants.length - 1){
        //     count === 0 && setIsCompleteMode(false)
        // }
    }
    );



    const handleIsMuted = (publication) => {
        if (publication.track.isEnabled) publication.track.disable()
        else publication.track.enable()
        setIsMuted(publication.track.isEnabled)
    }
    const handleIsScreen = (publication) => {
        debugger
        if (publication.track.isEnabled) {
            (async () => {
                setIsLocalHidden(true)
                await publication.track.disable()
            })()
        } else {
            (async () => {
                setIsLocalHidden(false)
                await publication.track.enable()
            })()
        }
        setIsScreen(publication.track.isEnabled)
    }
    const handleIsShared = (publication) => {
        setIsShared(!isShared)
    }


    return (

        <div className="videocall">
            <div className={!localZoom.data.isCompleteMode ? "videocall-participants" : 'videocall-participants-right'}>
                {room && screenSharedRemote > -1 && participant.videoTracks.size > 0 ? (
                    <>
                        {camerasOff.includes(fullSid) || !localZoom.data.isCompleteMode ? remoteParticipants : fullScreen}
                    </>
                ) : (
                    ""
                )}
                {room ? (
                    <>
                        {localZoom.data.isCompleteMode && !camerasOff.includes(fullSid) ?
                            <div className='videocall-participants-right-scroll'>{participantsRight}</div> : ''}
                    </>
                )
                    :
                    ''
                }
            </div>

            <div className='videocall-images'>
                {
                    room &&
                    <img alt='video' className={!isScreen && 'videocall-images-screenoff'} src={!isScreen ? cameraOffImg : cameraImg} onClick={() => {
                        room.localParticipant.videoTracks.forEach(publication => {
                            handleIsScreen(publication)
                        })
                        setIsCompleteMode(true)
                    }} />
                }
                {
                    room &&
                    <img alt='audio' className={!isMuted && 'videocall-images-micoff'} src={!isMuted ? micOffImg : micOnImg} onClick={() => {

                        // room.localParticipant.dataTracks.get(
                        //     room.localParticipant.dataTracks.keys().next().value).track.send('mute')
                        room.localParticipant.audioTracks.forEach(publication => {
                            handleIsMuted(publication)
                        })
                    }} />
                }
                {
                    room &&
                    <img alt='share' className={isShared && 'videocall-images-shared'} src={shareScreenImg} onClick={() => {
                        if (!screenTrack) {
                            navigator.mediaDevices.getDisplayMedia().then(stream => {
                                (async () => {
                                    let screenTrack = new LocalVideoTrack(stream.getTracks()[0], { name: 'screen' })
                                    await room.localParticipant.publishTrack(screenTrack)
                                    setScreenTrack(screenTrack)
                                    handleIsShared()
                                    debugger
                                    debugger
                                    // const localDataTrackScreen = new LocalDataTrack();
                                    // debugger
                                    // localDataTrackScreen.send('screen')
                                    await room.localParticipant.dataTracks.get(
                                        room.localParticipant.dataTracks.keys().next().value)?.track.send('screen')
                                    // debugger
                                    // await room.localParticipant.dataTracks.delete(
                                    //     room.localParticipant.dataTracks.keys().next().value)
                                })()
                            }).catch(() => {
                                alert('Could not share the screen.')
                            });


                        } else {
                            debugger
                            (async () => {
                                debugger
                                await room.localParticipant.unpublishTrack(screenTrack)
                                debugger
                                await screenTrack.stop()
                                setScreenTrack(undefined)
                                
                                debugger
                                debugger
                                handleIsShared()
                                // const localDataTrackScreen2 = new LocalDataTrack();
                                // debugger
                                // await localDataTrackScreen2.send('screenoff')
                                await room.localParticipant.dataTracks.get(
                                    room.localParticipant.dataTracks.keys().next().value)?.track.send('screenoff')
                            })()
                        }
                    }} />
                }
                {/* {
                    room &&
                    <img alt='record' src={recordImg} onClick={() => {

                    }} />
                } */}
                {
                    room &&
                    <img alt='hand' className={wantToSpeak && 'videocall-images-speak'} src={handImg} onClick={() => {
                        // if (room.localParticipant.dataTracks.size === 0) {
                        //     (async () => {
                        //         await room.localParticipant.publishTrack(dataTrack)
                        //         room.localParticipant.dataTracks.get(
                        //             room.localParticipant.dataTracks.keys().next().value)?.track.send('hand')
                        //     })()
                        // } else {
                        //     (async () => {
                        //         debugger
                        //         //    await room.localParticipant.dataTracks.get(
                        //         //      room.localParticipant.dataTracks.keys().next().value)?.track.send('unpublish-hand')
                        //         await room.localParticipant.dataTracks.get(room.localParticipant.dataTracks.keys().next().value).unpublish()
                        //     })()
                        // }

                        // participantsHands.some(p => p.sid === participant.sid) ?
                        //     setParticipantsHands((prevParticipantsHands) =>
                        //         prevParticipantsHands.filter((p) => p.sid !== participant.sid)
                        //     ) :
                        //     setParticipantsHands((prevParticipantsHands) => [...prevParticipantsHands, participant])
                        setWantToSpeak(!wantToSpeak)
                        debugger
                        room.localParticipant.dataTracks.get(
                            room.localParticipant.dataTracks.keys().next().value)?.track.send('hand')



                        participantsHands.some(p => p.sid === participant.sid) ?
                            setParticipantsHands((prevParticipantsHands) =>
                                prevParticipantsHands.filter((p) => p.sid !== participant.sid)
                            ) :
                            setParticipantsHands((prevParticipantsHands) => [...prevParticipantsHands, participant])
                        // setWantToSpeak(!wantToSpeak)
                    }} />
                }
                {
                    room &&
                    <PowerSettingsNewIcon className={'videocall-images-exit'} onClick={() => {
                        dispatch({
                            type: SET_LOCAL_ZOOM,
                            payload: {
                                isCompleteMode: false,
                                fullSid: '',
                                cameraOrScreen: ''
                            }
                        })
                        handleLogout()
                        localStorage.removeItem('token')
                        localStorage.removeItem('roomName')
                    }} />
                }
            </div>
            {
                room && participants?.length ?
                    <CamerasOff
                        participants={participants}
                        room={room} setParticipants={setParticipants}
                        setCamerasOff={setCamerasOff}
                        setIsChangeMute={setIsChangeMute}
                        changeMute={changeMute}
                        isLocalHidden={isLocalHidden}
                    /> :
                    ''
            }
        </div >
    );
}

export default UbelongVideoCall;
