import React from 'react'
import './Form.scss';

function Form({ changeName, changeRoomName, changeModerator, moderator, onSubmit }) {
    return (
        <div className="form">
            <h3>Acceso a la videollamada</h3>
            <input placeholder='Nombre' onChange={changeName} />
            <input placeholder='Sala' onChange={changeRoomName} />
            <p>Eres moderador?</p>
            <div className='form-radio'>
                <p>Si</p>
                <input checked={moderator ? true : false} type='radio' onChange={()=>changeModerator(true)} />
                <p>No</p>
                <input checked={!moderator ? true : false} type='radio' onChange={()=>changeModerator(false)}/>
            </div>

            <div className='form-button' onClick={onSubmit}>Entrar</div>
        </div>
    );
}

export default Form;
