export const getCameraBorderRadius = (numParticipants, i) => {
    
    let borderRadius = ''
    if (numParticipants <= 4) {
        switch (numParticipants) {
            case 1:
                borderRadius = '12px 12px 0px 0px'
                break;
            case 2:
                borderRadius = '12px 12px 12px 12px'
                break;
            case 3:
                borderRadius = '12px 12px 12px 12px'
                break;
            case 4:
                if (i === 0) borderRadius = '0px 12px 0px 0px'
                if (i === 1) borderRadius = '12px 0px 0px 0px'
                if (i === 2) borderRadius = '0px 0px 0px 0px'
                if (i === 3) borderRadius = '0px 0px 0px 0px'
                break;
            default:
                break;
        }
    } else {
        const rigthBorderRadiusCamera = Math.ceil(Math.sqrt(numParticipants))

        if (i === 0) borderRadius = '0px 12px 0px 0px'
        if (i === rigthBorderRadiusCamera - 1) borderRadius = '12px 0px 0px 0px'
    }
    return borderRadius
}
