import { combineReducers } from 'redux'
import localParticipantReducer from './localParticipantReducer'
import localSidZoom from './localSidZoom'

const rootReducer = combineReducers({
    localParticipantReducer,
    localSidZoom    
})

export default rootReducer
