import * as actionType from "../states"

const initialState = {
    data: {
        name: '',
        roomName: '',
        moderator: false
    }
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionType.SET_LOCAL_PARTICIPANT:
            return {
                ...state,
                data: payload
            }

        default:
            return state
    }
}
