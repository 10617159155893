import React, { useEffect, useState } from 'react'
import './CamerasOff.scss'



function CamerasOff({ 
    participants, 
    room, 
    setParticipants, 
    setCamerasOff, 
    setIsChangeMute, 
    changeMute,
    isLocalHidden }) {

    const [changeCamera, setChangeCamera] = useState(0)

    useEffect(()=>{   
        setChangeCamera(changeCamera + 1)
    },[isLocalHidden])


    useEffect(() => {
        const disableTrack = (track, participant) => {
            //     setParticipants((prevParticipants) =>
            //     prevParticipants.filter((p) => p !== participant)
            // );
            if (track.kind === 'video') {
                setCamerasOff((prevCameras) =>
                    [...prevCameras, participant.sid]
                )
                setChangeCamera(changeCamera + 1)
            }
            if(track.kind === 'audio'){
                setIsChangeMute(changeMute + 1)
            }
        };
        const enableTrack = (track, participant) => {
            // setParticipants(prevParticipants => {
                //     return [...prevParticipants, participant]
                // })
                if (track.kind === 'video') {
                setCamerasOff((prevCameras) =>
                prevCameras.filter((p) => p !== participant.sid)
                );
                setChangeCamera(changeCamera - 1)
            }
            if(track.kind === 'audio'){
                setIsChangeMute(changeMute - 1)
            }
        };
        room.on("trackDisabled", disableTrack)
        room.on("trackEnabled", enableTrack)
        return () => {
            room.off("trackDisabled", disableTrack)
            room.off("trackEnabled", enableTrack)
        };
    }, [room]);

    const handleOnSetName = (participant) => {
        const name = participant.identity.split(' ')
        if (name.length === 1) return `${name[0][0]}`
        if (name.length > 1) return `${name[0][0]}${name[1][0]}`
    }

    const handleOnCreateAvatar = () => participants.map(participant => {
        if (!participant?.videoTracks?.get(participant?.videoTracks?.keys()?.next()?.value)?.isTrackEnabled) {
            return < div className='cameras-off-person'>{handleOnSetName(participant)}</div>
        }
    })

    return (
        <div className="cameras-off">
            {!isNaN(changeCamera) && handleOnCreateAvatar()}
        </div >
    )
}

export default CamerasOff
